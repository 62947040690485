import { gql } from "@apollo/client";
import { useValidation } from "vue3-form-validation";
import { useMutation } from "@vue/apollo-composable";
import { MutationContactArgs } from "@/graphql/types";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { validMail } from "@/graphql/utils/send-mail";

type ContactData = {
  contact: boolean;
};

const CONTACT = gql`
  mutation Contact($input: EmailOptions!) {
    contact(input: $input)
  }
`;

export const useContact = () => {
  const toast = useToast();
  const { t } = useI18n();
  const { validateFields, hasError, form, resetFields } = useValidation({
    subject: {
      $value: "",
      $rules: [(n: string) => !n && ""],
    },
    from: {
      $value: "",
      $rules: [(n: string) => !n && ""],
    },
    to: {
      $value: "",
      $rules: [(m: string) => !validMail(m) && ""],
    },
    text: {
      $value: "",
      $rules: [(n: string) => !n && ""],
    },
  });
  const { loading, mutate, onDone } = useMutation<
    ContactData,
    MutationContactArgs
  >(CONTACT);
  onDone(({ data }) => {
    const severity = data?.contact ? "success" : "error";
    toast.add({
      severity,
      summary: t("sendMail.title"),
      detail: t(`sendMail.${severity}`),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
    if (data) resetFields();
  });
  function sendMail() {
    validateFields().then((input) => {
      void mutate({ input });
    });
  }
  return {
    hasError,
    form,
    loading,
    sendMail,
  };
};
