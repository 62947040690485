
import { defineComponent } from "vue";
import { useContact } from "@/graphql/contact/contact";

export default defineComponent({
  name: "HomeFooter",
  setup() {
    return {
      contacts: [
        /*{
          value: process.env.VUE_APP_CONTACT_PHONE,
          icon: "pi pi-phone",
        },*/
        {
          value: process.env.VUE_APP_EMAIL_ADDRESS,
          icon: "pi pi-envelope",
        },
      ],
      ...useContact(),
    };
  },
});
