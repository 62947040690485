
import { defineComponent, onMounted } from "vue";
import { featureIcons } from "@/layouts/workspace/menu-settings";
import HomeFooter from "@/components/home/HomeFooter.vue";
export default defineComponent({
  name: "DocumentationLayout",
  components: {
    HomeFooter,
  },
  setup() {
    onMounted(() => {
      const body = document.querySelector("body"),
        sidebar = body.querySelector("nav"),
        toggle = document.querySelector(".toggle");
      toggle.addEventListener("click", () => {
        sidebar.classList.toggle("close");
      });
    });
    return {
      menu: [
        "account",
        "activity",
        "category",
        "product",
        "stock",
        "customer",
        "expense",
        "pos",
        "rapport",
        "dashboard",
      ],
      featureIcons,
    };
  },
});
